.feodal-info-table {
    background-color: #F4F4F4;
    .header {
        display: flex;
        align-items: center;
        h2 {
            font-size: 18px;
            font-weight: 600;
            color: #8F634C;
        }
        a {
            margin-right: 5%;
        }
    }
    .cad-number {
        font-size: 22px;
        font-weight: 300;
        text-align: center;
    }
    .card {
        border: 0;
        border-bottom: 2px solid #FFFFFF;
        margin: 10px;
        border-radius: 0;
        background-color: #F4F4F4;
        .card-header {
            position: relative;
            border-bottom: none;
            padding: 0;
            margin: 2rem 1rem;
            background-color: transparent;
            color: #8F634C;
            font-size: 18px;
            font-weight: 400;
            cursor: pointer;
            [data-toggle="collapse"] {
                &:after {
                    content: url('../../../static/v2-img/icons/angle_down.svg');
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translate(0, -50%);
                    transition: 1s;
                }
                &[aria-expanded="true"] {
                    &:after {
                        transform: translate(0, -50%) rotate(180deg);
                    }
                }
            }
            .title {
                display: flex;
                align-items: center;
                .order-number {
                    margin-right: 20px;
                    color: #DA9501;
                    font-weight: 300;
                    font-size: 16px;
                }
                .text {
                    color: #694938;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }
        .card-body {
            padding: 1.25rem 2.6rem;
            .key {
                color: #8D919A;
                font-size: 14px;
                font-weight: 400;
            }
            .value {
                color: #8D919A;
                font-size: 14px;
                font-weight: 300;
            }
        }
    }
}

.scoring-block {
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #F4F4F4;
    .header {
        padding: 10px 0px;
        font-size: 28px;
        color: #DA9501;
        text-align: center;
    }
}

