input[type="checkbox"] {
    &.flipswitch {
        position: relative;
        width: 45px;
        height: 23px;
        -webkit-appearance: initial;
        border-radius: 60px;
        outline: none;
        cursor: pointer;
        background-color: #EEEEEC;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
        transition: all 0.3s ease-in 0s;
        &:after {
            left: 2%;
            content: "";
            position: absolute;
            top: 5%;
            width: 45%;
            height: 90%;
            transition: all 0.3s ease-in 0s;
            border-radius: 60px;
            background-color: #ffffff;
        }
        &:checked {
            background-color: #DA9501;
            transition: all 0.3s ease-in 0s;
            &:after {
                left: 53%;
                content: "";
            }
            +label {
                font-weight: 400;
            }
        }
      }
}


.addBlock {
    display: flex;
    justify-content: space-between;
    margin: 90px 10px 0;
    .addAdvert {
        background-color: #ffffff;
        padding: 22px 32px;
        position: relative;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
        &:after {
            background-color: #f8aa02;
            width: 85%;
            height: 85%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            border-radius: 50%;
            content:"+";
            color: #ffffff;
            font-size: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
        }
        .textButton {
            display: flex;
            background-color: #ffffff;
            width: 133px;
            height: 43px;
            position: absolute;
            left: 55%;
            top: 15%;
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
            border-radius: 70px;
            color: #DA9501;
            text-decoration: none;
            padding-left: 39px;
            text-transform: lowercase;
            font-size: 14px;
            z-index: -1;
            align-items: center;
            &:hover {
                z-index: 0;
                color: #ffffff;        
                cursor: pointer;
                &:after {
                    z-index: -1;
                    content: "";
                    background-color:  #f8aa02;
                    width: 95%;
                    height: 87%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    border-radius: 70px;
                }
            }
        }
        &:hover {
            .textButton {
                z-index: 0;
                color: #ffffff;        
                cursor: pointer;
                &:after {
                    z-index: -1;
                    content: "";
                    background-color:  #f8aa02;
                    width: 90%;
                    height: 80%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    border-radius: 70px;
    
                }
            }
        }
    }
    .message {
        background-color: #ffffff;
        padding: 22px 32px;
        position: relative;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
        &:after {
            background-color: #f8aa02;
            width: 85%;
            height: 85%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            border-radius: 50%;
            content: url('../v2-img/icons/message.svg');
            color: #ffffff;
            font-size: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
        }
    }
}
