.owl-carousel {
	.carousel-btn {
		position: absolute;
		cursor: pointer;
		bottom: 5px;
		background: none;
	}
	.owl-prev.disabled {
		pointer-events: none;
		opacity: 0.2;
	}
	.owl-next.disabled {
		pointer-events: none;
		opacity: 0.2;
	}
	.prev-slide {
		left: 40%;
		&:hover {
			background-position: 0px -53px;
			color: #DA9501;
		}
	}
	.next-slide {
		right: 40%;
		&:hover {
			background-position: -24px -53px;
			color: #DA9501;
		}
    }
    .owl-dots {
        .owl-dot {
            &.active {
                span {
                    background-color: #DA9501 !important;
                }

            }
            &:hover {
                span {
                    background-color: #dda361 !important;
                }
            }
        }
    }
    .owl-nav {
        i {
            font-size: 20px;
            font-weight: 600;
            color: #252628;
            opacity:0.3;
        }
        .prev-slide, .next-slide {
            &:hover {
                i {
                    color: #DA9501;
                    opacity: 1;
                }
            }
        }
    }
}