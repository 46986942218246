.breadcrumbs {
    background: #EEEEEC;
    padding:14px 40px;
    .breadcrumb {
        margin: 0;
        background: transparent;
        ul {
            display: flex;
            margin: 0;
            padding: 0;
            flex-wrap: wrap;
            li {
                flex-direction: row;
                &:not(:last-child) {
                    margin-right: 5px;
                    &:after {
                        padding-left: 5px;
                        content: '>';
                    }
                }
                a {
                    color: #252628;
                    font-size: 14px;
                    transition: color 0.3s;
                    &.active,
                    &:hover {
                        color: #DA9501;
                    }
                }
            }
        }
    }
}
