// footer
.footer {
  .logo {
      order: 0;
  }
  .list {
      order: 1;
  }
  .contact {
      order: 2;
  }
  .notification {
      order: 3;
  }
  background-color: #EEEEEC;
  padding-top: 40px;

  a {
    color: #8F634C;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: #d49000;
    }
  }

  img {
    max-width: 100%;
  }

  .copyright {
    display: block;
    color: #959595;
    font-size: 14px;
    font-weight: 500;
    background-color: #252628;
    text-align: center;
    padding: 10px 0;
  }

  .card-icons {
    width: auto;
    height: 40px;
  }
}