.btn {
    border-radius: 5px;
    white-space: normal;
    &.btn-success {
        background-color: #DA9501;
        color: #ffffff;
        border: 1px solid #DA9501;
        &:hover {
            background-color: #FFFFFF;
            color: #DA9501;
        }
        
    &:not(:disabled):not(.disabled):active {
        background-color: lighten(#DA9501, 5%);
        border-color: lighten(#DA9501, 5%);
      }
  
      &:not(:disabled):not(.disabled):active:focus,
      &:not(:disabled):not(.disabled):focus {
        box-shadow: 0 0 0 0.2rem rgba(lighten(#DA9501, 5%), .5);
    }
    }
    &.btn-outline-success {
        border: 1px solid #DA9501;
        color: #DA9501;
        &:hover {
            background-color: #DA9501;
            color: #ffffff;
        }
        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):active {
          color: white;
          background-color: darken(#DA9501, 1%);
          border-color: darken(#DA9501, 1%);
          box-shadow: 0 0 0 0.2rem rgba(darken(#DA9501, 1%), .5);
        }
      }
    
      &.btn-outline-light {
        color: darken(#f8f9fa, 40%);
        background-color: white;
        border-color: darken(#f8f9fa, 40%);;
      }
      &.btn-link {
        color: #252628;
        background: none;
    
        &:hover {
          text-decoration: none;
          color: #d49000;
        }
    
        &:focus {
          text-decoration: underline;
          color: #d49000;
        }
      }
}


.subscribeButton {
    &:before {
        content: url('../v2-img/icons/bell.svg');
        margin-right: 10px;
        vertical-align: -2px;
    }
    &:hover {
        &:before {
            content: url('../v2-img/icons/bell_white.svg');
        }
    }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active {
        &:before {
            content: url('../v2-img/icons/bell_white.svg');
        }
    }
}

.notificationButton {
    &:before {
        content: url('../v2-img/icons/bell_white.svg');
        margin-right: 15px;
        vertical-align: -2px;
    }
    &:hover {
        &:before {
            content: url('../v2-img/icons/bell.svg');
        }
        .number {
            color: #ffffff;
        }
    }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active {
        &:before {
            content: url('../v2-img/icons/bell_white.svg');
        }
    }
    
}

.view-more {
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:hover {
        svg {
            animation: slide 1s ease-in-out infinite;
        }
    }
}

.badge-danger {
    font-size: 22px;
    border-radius: 6px;
    font-weight: 300;
}

.submit-button {
    align-self: flex-end;
}


@keyframes slide {
    0% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(0px, 7px);
    }
    100% {
        transform: translate(0px, 0px);
    }
}