@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.widget-body {
    background: #002348;
    .widget {
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-style: normal;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        background: #002348;
        padding: 50px 30px 30px;
        @media (max-width: 1500px) {
            padding: 35px 20px 25px;
        }
        @media (max-width: 1199px) {
            padding: 20px;
        }
        @media (max-width: 575px) {
            padding: 20px 20px 15px;
            min-height: 385px;
        }
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        color: #fff;
        max-width: 600px;
        .widget-header {
            display: flex;
            @media (max-width: 1500px) {
                margin-bottom: 40px;
            }
            span {
                -webkit-box-flex: 1;
                -webkit-flex: 1 1 auto;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                color: #fff;
                text-transform: uppercase;
                line-height: 150%;
                font-weight: 600;
                font-size: 24px;
                @media (max-width: 1500px) {
                    font-size: 18px;
                }
                letter-spacing: 0.05em;
            }
        }
        .filters {
            margin-bottom: 71px;
            @media (max-width: 1199px) {
                margin-bottom: 40px;
            }
        }
        .filter-button-box {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            border: 2px solid #F3115B;
            background: #F3115B;
            padding: 18px 40px;
            @media (max-width: 1366px) {
                font-size: 14px;
            }
            @media (min-width: 575px) and (max-width: 1200px) {
                padding: 11px 20px;
            }
            @media (max-width: 575px) {
                padding: 11px 20px;
            }
            transition: all .5s ease;
            .filter-button {
                color: #FFFFFF;
            }
            &:hover,
            &:focus {
                box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
                cursor: pointer;
                border: 2px solid #b3023d;
                svg {
                    path {
                        fill: #FFFFFF;
                    }
                }
            }
        }
        span.select2.select2-container {
            min-width: 100% !important;
            max-width: 181px;
        }
        
        .select2-container--material {
            .select2-selection--single {
                background-color: transparent;
                border: none;
                border-radius: 0;
                box-shadow: none;
                box-sizing: content-box;
                height: 50px;
                margin: 4px 0;
                outline: none;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                text-align: center;
                border: 1px solid #A9A9A9;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 10px;
                .select2-selection__rendered {
                    color: #FFD200;
                    line-height: 28px;
                    padding-left: 10px;
                    font-weight: 400;
                    height: 50px;
                    display: flex;
                    align-items: center;
                }
                .select2-selection__clear {
                    display: none;
                    cursor: pointer;
                    float: right;
                    font-weight: bold;
                }
                .select2-selection__placeholder {
                    color: white;
                    font-weight: 400;
                }
                .select2-selection__arrow {
                    content: url('/static/v2-img/icons/widget-arrow-right.svg');
                    height: 17px;
                    margin: 0;
                    position: relative;
                    top: 0;
                    left: 0;
                    transition: content 0.3s;
                }
                &.pointer-none {
                    pointer-events: none;
                    .select2-selection__placeholder {
                        color: #9e9d9d;
                    }
                }
            }
        }
        .select2-container--material.select2-container--material--open {
            .select2-selection--single {
                .select2-selection__arrow {
                    b {
                        border-color: transparent transparent #000 transparent;
                        border-width: 0 4px 5px 4px;
                    }
                }
            }
        }
        .select2-container--open {
            .select2-selection--single {
                .select2-selection__arrow {
                    content: url('/static/v2-img/icons/widget-arrow-down.svg');
                    height: 17px;
                    margin: 0;
                    position: relative;
                    top: 0;
                    left: 0;
                }
            }
            .select2-selection__rendered {
                .select2-selection__placeholder {
                    color: #FFD200;
                }
            }
            .select2-dropdown {
                left: 0;
                border-color: #d4d5d8;
            }
            .select2-dropdown--above {
                border-bottom: none;
                border-bottom-left-radius: 0 ;
                border-bottom-right-radius: 0;
            }
            .select2-dropdown--below {
                border-top: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border: 1px solid #efefed;
            }
        }
        .select2-search--dropdown.select2-search--hide {
            display: none;
        }
    }
    
    .select2-search--dropdown {
        padding: 8px;
        .select2-search__field {
            background: #F3F3F3;
            border-radius: 6px;
            height: 45px;
            border: 0;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
            color: #000000;
            font-weight: 600;
            font-size: 16px;
            &:focus {
                &:not([readonly]) {
                    box-shadow: none;
                    border-bottom: 0;
                }
            }
        }
    }
    .select2-results {
        > .select2-results__options {
            max-height: 200px;
            overflow-y: auto;
            padding: 8px;
            .select2-results__option {
                padding: 10px;
                border-bottom: 1px solid #F3F3F3;
            }
        }
        .select2-results__option--highlighted[aria-selected] {
            background-color: #F3F3F3;
            color: rgb(0, 0, 0);
            border-radius: 6px;
        }
    }

    .trevda-type {
        display: flex;
        flex-wrap: wrap;
        margin: 1rem 0;
        padding:0;
        .option {
            width: 50%;
            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
                position: absolute;
                left: -9999px;
            }
        
            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label {
                position: relative;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                color: #D7D6D4;
                background-color: transparent;
                border-bottom: 1px solid #D7D6D4;
                padding: 14px 0px;
                width: 100%;
                text-align: center;
                font-weight: 600;
                font-size: 20px;
                text-transform: uppercase;
                @media (max-width: 1199px) {
                    font-size: 14px;
                }
            }
        
            [type="radio"]:checked + label {
                color: #FFD200;
                box-shadow: 0px 2px 0px 0px;
            }
    
            [type="radio"]:not(:checked) + label {
                &:hover {
                    color: #FFD200;
                    box-shadow: 0px 2px 0px 0px;
                }
            }
            &:last-child {
                display: none;
            }

        }
    }
    
}
