#searchModal {
    .modal-content {
        opacity: 0.97;
        border-radius: 3px;
        border: 0;
        .modal-header {
            padding: 0;
            border: 0;
            .close {
                position: absolute;
                right: -7px;
                top: -10px;
                padding: 13px 17px;
                border-radius: 50%;
                background: #FFFFFF;
                box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
                opacity: 1;
                color: #DA9501;
                font-weight: 300;
            }
        }
    }
    
}

.widget-preview {
    .messenger {
        bottom: 73px;
        right: 8px;
        .messenger__switch {
            background-color: #DA9501 !important;
        }
    }
}


.alert {
    .alert-info {
        color: #715242;
        background-color: #dc9a0e3d;
        border-color: #dc9a0e3d;
    }
}
.telegram-modal-wrapper {
    position: fixed;
    bottom: 25px;
    left: 3em;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    margin-left: -300px;
    transition: margin .5s;
    &.show {
        opacity: 1;
        visibility: visible;
        margin-left: 0;
    }
    .telegram-modal {
        position: relative;
        width: 250px;
        height: 150px;
        padding: 10px;
        margin: 0 10px;
        background: #FFFFFF;
        color: #8f634c;
        font-weight: 400;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.1));
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
            content: "";
            position: absolute;
            bottom: -25px;
            left: 60%;
            border-style: solid;
            border-width: 26px 25px 0;
            border-color: #FFFFFF transparent;
            display: block;
            width: 0;
            z-index: 1;
        }
        &:before {
            content: "";
            position: absolute;
            top: 148px;
            left: 60%;
            border-style: solid;
            border-width: 26px 25px 0;
            border-color: #ffffff transparent;
            display: block;
            width: 0;
            z-index: 0;
        }
        a {
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 20px;
            transition: .4s;
            img {
                width: 40px;
                height: 40px;
            }
            &:hover {
                box-shadow: 0px 4px 10px 0px #b5a175;
            }
        }
    }
    .telegram-close {
        width: 30px;
        height: 30px;
        position: absolute;
        top: -30px;
        right: -13px;
        border-radius: 50%;
        background-color: #FFFFFF;
        filter: drop-shadow(2px 3px 5px rgba(0, 0, 0, 0.1));
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            cursor: pointer;
            svg {
                path {
                    stroke: #714d00;
                }
            }
        }
    }
}
