.buttons-toggle {
    background-color: #e2ebf2;
    width: 301px;
    border-radius: 60px;
    label {
		color: black;
		font-size: 18px;
		text-align: center;
		padding: 7px 40px;
		margin: 0;
		border-radius: 60px;
		transition: 0.3s;
		cursor: pointer;
		background-color: #e2ebf2;
		&:hover {
			background-color: darken(#e2ebf2, 3%);
		}
    }

    input[type="radio"],
    input[type="checkbox"] {
		visibility: hidden;
		position: absolute;
    }

    input[type="radio"]:checked + label,
    input[type="checkbox"]:checked + label {
		background-color: #d49000;
		color: white;

		&:hover {
			background-color: lighten(#d49000, 3%);
		}
    }
}

.js-switch {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.pai {
        .switch-label {
            &::before, &::after { 
                padding: 14px 40px;
            }
        }
    }
}
  
.switch {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 301px;
    height: 45px;
    padding: 1px;
    border-radius: 3px;
    cursor: pointer;
    .switch-input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		&:checked ~ .switch-handle {
			left: 110px;
			box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
		}
		&:checked ~ .switch-label {

		}
		&:checked ~ .switch-label:before {
			opacity: 1;
			background-color: #e2ebf2;
			color: black;
		}
		&:checked ~ .switch-label:after {
			opacity: 1;
			background: #d49000;
			color: white;
		}
    }
    .switch-label {
		position: relative;
		display: block;
		height: 44px;
		font-size: 10px;
		background-color: #e2ebf2;
		border-radius: 60px;
		transition: 0.15s ease-out;
		margin-top: 4px;
		&::before, &::after {
			position: absolute;
			top: 0;
			margin-top: 0;
			line-height: 1;
			padding: 6px 40px;
			font-size: 16px;
			width: 50%;
			border-radius: 60px;
			-webkit-transition: inherit;
			-moz-transition: inherit;
			-o-transition: inherit;
			transition: inherit;
			text-align: center;
		}
		&::before {
			content: attr(data-off);
			right: 0;
			color: rgb(255, 255, 255);
			background: #d49000;
		}
		&::after {
			content: attr(data-on);
			left: 0;
			color: rgb(0, 0, 0);
			opacity: 1;
			background-color: #e2ebf2;
		}
    }
}
  
.switch-handle {
    display: none;
    position: absolute;
    top: 1px;
    left: -5px;
    width: 36px;
    height: 45px;
    background: white;
    border-radius: 3px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
    transition: left 0.15s ease-out;
    margin-top: 4px;
    &::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -6px 0 0 -6px;
		width: 12px;
		height: 12px;
		background: #f9f9f9;
		border-radius: 6px;
		box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
		background-image: linear-gradient(to bottom, #eeeeee, white);
	}
}

.collapse-form {
    display: none;
}

.create-number-row {
    button {
        
    }
    .collapse-form {
		input[type="text"] {
			display: block;
			width: 100%;
			padding: .375rem .75rem;
			font-size: 1rem;
			line-height: 1.5;
			color: #495057;
			background-clip: padding-box;
			border: 2px solid #EEEEEC;
			border-radius: 5px;
			background-color: transparent;
			transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
			&:focus {
				border: 2px solid #DA9501;
			}
		}
    }
}