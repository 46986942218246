.news {
    width: 100%;
    margin: 45px auto;
    background: #FDFDFD;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.05);     
    .owl-carousel {
        .owl-item {
            .order-number {
                color: white;
                position: absolute;
                font-size: 40px;
                right: 2rem;
                bottom: 2rem;
                font-weight: 400;
                font-family: 'Concert One', serif;
            }
        }
        .owl-stage-outer {
            position: relative;
        }
        .owl-nav {
            .owl-next {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
                color: white;
                font-size: 5rem;
            }
            .owl-prev {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(15px, -50%);
                color: white;
                font-size: 5rem;
            }
            > button {
                transition: 0.5s;
                &:hover {
                    background: none;
                    color: #d8d8d8;
                }
            }
        }
        img {
            border-radius: 5px;
            height: fit-content;
        }
        .owl-dots {
            display: none;
        }
    }
    .accordion {
        max-height: 405px;
        overflow: auto;
        .card {
            border: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            margin: 10px;
            border-radius: 0;
            .card-header {
                position: relative;
                border-bottom: none;
                padding: 0;
                margin: 0.75rem 1rem;
                background-color:#FDFDFD;
                color: #8F634C;
                font-size: 18px;
                font-weight: 400;
                cursor: pointer;
                [data-toggle="collapse"] {
                    &:after {
                        content: url('../../../static/v2-img/icons/angle_down.svg');
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translate(0, -50%);
                        transition: 1s;
                    }
                    &[aria-expanded="true"] {
                        &:after {
                            transform: translate(0, -50%) rotate(180deg);
                        }
                    }
                }
                .title {
                    display: flex;
                    .order-number {
                        margin-right: 8px;
                        color: #DA9501;
                        font-weight: 300;
                        font-size: 16px;
                    }
                    .text {
                        color: #694938;
                        font-size: 18px;
                    }
                }
            }
            .card-body {
                padding: 1.25rem 2.6rem;
                .text {
                    color: #8D919A;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 130%;
                }
                .date {
                    color: #8D919A;
                    font-size: 13px;
                }
                .more {
                    a {
                        color: #DA9501;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.top-news {
    .item {
        .text {
            color: #8D919A;
            font-weight: 300;
            font-size: 14px;
            line-height: 130%;
        }
        .date {
            color: #8D919A;
            font-size: 13px;
        }
        .more {
            a {
                color: #DA9501;
                font-size: 13px;
            }
        }
    }
}

.news-list {
    .item {
        border: 0;
        margin: 15px 0;
        .title {
            color: #694938;
            font-size: 18px;
            font-weight: 400;
            border-top: 1px solid #8d919a7a;
            padding-top: 8px;
        }
        .news-content {
            .image{
                float: left;
                width: 50%;
                margin: 0 10px 10px 0;
            }
            .text {
                justify-content: center;
            }
            p {
                a {
                    color: #DA9501;
                    text-decoration: underline;
                }
            }
        }
        .text {
            strong {
                font-weight: 500;

            }
        }
        .date {
            color: #694938;
            font-size: 14px;
            font-weight: 400;
        }
        .more {
            a {
                color: #DA9501;
                font-size: 14px;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .tags {
            font-size: 14px;
        }
    }
}

.news-select {
    .select{ 
        border: 0;
        min-height: 45px;
        background-color: transparent;
        text-align: left;
        color: #575757;
        .select-styled {
            display: flex;
            align-items: center;
            border: 2px solid #EEEEEC;
            border-radius: 5px;
            &:after {
                top: 20px;
                border-color: #575757 transparent transparent transparent;
            }
        }
        .select-options {
            border-radius: 4px;
            li {
                border: 0;
                border-bottom: 1px solid #EEEEEC;
            }
        }
    }
}