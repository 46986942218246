@font-face {
    font-family: "Concert One";
    src: url('../fonts/ConcertOne/ConcertOne-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/ConcertOne/ConcertOne-Regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/ConcertOne/ConcertOne-Regular.woff') format('woff'), /* Pretty Modern Browsers */
          url('../fonts/ConcertOne/ConcertOne-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}

// @font-face {
// 	font-family: "Open Sans";
//         src: url('../fonts/OpenSans/OpenSans-Regular.eot'); /* IE9 Compat Modes */
//         src: url('../fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'), /* Super Modern Browsers */
//                 url('../fonts/OpenSans/OpenSans-Regular.woff') format('woff'), /* Pretty Modern Browsers */
//                 url('../fonts/OpenSans/OpenSans-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */;
//         font-weight: 400;
// }

// @font-face {
//         font-family: "Open Sans";
//         src: url('../fonts/OpenSans/OpenSans-Light.eot'); /* IE9 Compat Modes */
//         src: url('../fonts/OpenSans/OpenSans-Light.woff2') format('woff2'), /* Super Modern Browsers */
//                 url('../fonts/OpenSans/OpenSans-Light.woff') format('woff'), /* Pretty Modern Browsers */
//                 url('../fonts/OpenSans/OpenSans-Light.ttf')  format('truetype'), /* Safari, Android, iOS */;
//         font-weight: 300;
//         }