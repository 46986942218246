ul {
    list-style-type: none;

    li {
        display: flex;
        flex-direction: column;
    }
}

ul.horizontal {
    li {
        display: inline;
    }
}


.mobile-menu {
    ul {
        li {
            a {
                color: #DA9501;
                &:hover {
                    color: #8F634C;
                }
            }
        }
    }
}

.menu-box {
    margin: 10px auto;
    ul {
        margin: 0 auto;
        padding: 0;
        width: fit-content;
        display: flex;
        flex-wrap: wrap;
        background: #eeeeec44;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 40px;
        li {
            border-radius: 60px;
            padding: 14px 20px;
            &:hover {
                background: #ffffff;
                cursor: pointer;
            }
            &.active {
                background: #ffffff;
                a {
                    color: #000000;
                    font-weight: 400;
                }
            }
        }
    }
}

.errorlist {
    margin: 0;
    padding: 0;
    li {
        font-weight: 500;
        color: #DA0142;
    }
}