.brown-header {
    color: #694938;
    font-size: 28px;
    text-align: center;
}

.block-brown-header {
    color: #694938;
    font-size: 18px;
    padding: 10px 0;
}

.info-culture {
    .culture, .area {
        font-size: 14px;
        color: #252628;
    }
    .area {
        font-weight: 600;
    }
}

.scoring-table {
    margin: 10px 0;
    .border-item {
        border-bottom: 2px solid #b6b6b6;
    }
    border-collapse: collapse;
    border-style: hidden;
    td {
        padding: 3px 10px;
        border: 1px solid #EEEEEC;
    }
}

.scoring {
    max-height: 500px;
    overflow-y: auto;
}

.empty-data {
    font-size: 20px;
    color: #8D919A;
    display: flex;
    justify-content: center;
    padding: 15px 0;
}

.apexcharts-tooltip {
    & > div {
        background: #8D919A;
        color: rgb(255, 255, 255);
    }
}

.overflow-chart-table {
    max-height: 400px;
    overflow: auto;
}

.apexcharts-legend {
    width: 200px;
}