@media (max-width: 1650px) { 
    .telegram-modal-wrapper {
        left: 1em;
    }
      
}
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
    .header-form {
        .container {
          padding: 12px 30px 12px 0;
        }
    }
      
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { 
    header {
        .mobile-menu {
            width: 40%;
            &.show {
                &:after {
                    width: 60%;
                }
            }
        }
        .navbar {
            justify-content: space-between;
            .burger-menu {
                display: block;
            }
            .logo {
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0%);
                padding: 0;
                img {
                    width: 150px;
                }
            }
            .navbar-nav {
                .nav-item {
                    &.menu {
                        display: none;
                    }
                    &.wishList {
                        display: none;
                    }
        
                }
            }
        }
    }

    main {
        min-height: calc(100vh - 55px - 458px);
        .header-form {
            .container {
              display: none;
            }
            .mobile-search {
                display: block;
            }
            .select2-container--material {
                .select2-selection--single {
                  border-right: none;
                  border-bottom: 1px solid #EEEEEC;
                  text-align: justify;
                  margin: 10px;
                }
                .select2-selection__arrow {
                    left: 95%;
                    top: 0;
                }
                .select2-selection__placeholder {
                    color: #000;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
            .select {
                border-right: none;
                border-bottom: 1px solid #EEEEEC;
                height: 36px;
              }
            .select-styled {
                font-size: 18px;
                font-weight: 400;
                text-align: justify;
                padding-left: 18px;
                margin-top: 8px;
                top: -5px;
                &:after {
                    right: 11px;
                  }
            }
        }
    }

    footer {
        &.footer {
            .logo {
                order: 0;
            }
            .list {
                order: 1;
            }
            .contact {
                order: 3;
            }
            .notification {
                order: 2;
                div {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    .addBlock {
        margin: 0 10px;
    }

    .trevda-profile {
        .item {
            .location {
                order: 1;
            }
            .states {
                order: 2;
            }
            .price {
                order: 3;
            }
            .area {
                order: 4;
            }
            .views {
                order: 5;
            }
        }
    }
    .news-list {
        .item {
            .news-content {
                .image{
                    width: 100%;
                    margin: 0 0px 10px 0;
                }
            }
        }
    }
    .landboard-map {
        width: 100% !important;
        height: 200px !important;
    }
    .messenger {
        display: none;
    }
    .scoring-result {
        div {
            &:nth-child(4), &:nth-child(5) {
                text-align: center;
                padding-top: 10px;
            }
        }
    }
}


// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
    header{
        .mobile-menu {
            width: 60%;
            &.show {
                &:after {
                    width: 40%;
                }
            }
        }
        .navbar {
            .navbar-nav {
                .nav-item {
                    &.languages {
                        div {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }
    .trevda-profile {
        .item {
            .location {
                order: 1;
            }
            .price {
                order: 2;
                margin: 8px 0;
            }
            .area {
                order: 3;
                margin: 8px 0;
            }
            .views {
                order: 4;
                margin: 8px 0;
            }
            .states {
                display: flex;
                justify-content: space-around;
                order: 5;
            }
        }
    }
    .trevda-list {
        margin: 0;
    }
    .scoring-result {
        align-items: center;
        div {
            &:nth-child(1) {
                order: 1;
                font-weight: 500;
            }
            &:nth-child(2) {
                order: 3;
                font-size: 14px;
            }
            &:nth-child(3) {
                order: 2;
            }
            &:nth-child(4) {
                order: 4;
                text-align: center;
                a {
                    svg {
                        transform: scale(2.5);
                    }
                    span {
                        display: none;
                    }
                }
            }
            &:nth-child(5) {
                order: 5;
                text-align: center;
                a {
                    svg {
                        transform: scale(2.5);
                    }
                    span {
                        display: none;
                    }
                }
            }
        }
    }
    .apexcharts-legend {
        width: 100%;
    }
}



// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
    header {
        .mobile-menu {
            width: 100%;
            font-size: 1.3rem;
            &.show {
                &:after {
                    width: 0%;
                }
            }
        }
        .navbar {
            .logo {
                img {
                    width: 115px;
                }
            }
            .navbar-menu-wrapper {
                .navbar-nav {
                    .nav-item {
                        margin: 0px 4px;
                    }
                }
            }
        }
    }
    .margin-md {
        margin: 2vw 2vw 0 2vw;
    }

}
