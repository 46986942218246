body {
    position: relative;
    // background-image: url('../v2-img/bg_img.png');
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: 300;
    font-family: 'Open Sans', sans-serif;
    transition: 0.3s;
    opacity: 0.95;
    margin: 0 auto;
}
main {
    min-height: calc(100vh - 55px - 273px);
    background: linear-gradient(#ffffff 25%, transparent), url('../v2-img/bg_img.png');
    background-size: cover;
}
.content {
    margin: 55px 0px 0px 0px;
}
.content-header {
    margin: 1vw 7vw;
}
.content-footer {
    width: 100%;
}

.bg-white {
    background-color: #ffffff;
}

.no-margin {
    margin: 0;
}

.margin-xs {
    margin: 2vw 3vw 0 3vw;
}

.margin-sm {
    margin: 2vw 7vw 0 7vw;
}

.margin-md {
    margin: 2vw 9vw 0 9vw;
}

.no-padding {
    padding: 0;
}

.padding-xs {
    padding: 2vw 3vw 0 3vw;
}

.padding-sm {
    padding: 2vw 7vw 0 7vw;
}

.padding-md {
    padding: 2vw 9vw 0 9vw;
}

.fa-check-circle {
    color: #DA9501;
}

.flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-column-between {   
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.vertical-align-middle {
    vertical-align: middle;
}

.landboard {
    &.card {
        padding: 5px 10px;
        margin: 0;
        .card-header {
            margin: 0;
            .text {
                span {
                    color: #252628;
                    font-size: 18px;
                    font-weight: 400;
                    transition: color 0.3s;
                }
                strong {
                    color:#8D919A;
                    font-weight: 300;
                }
            }
            &:hover {
                button {
                    span {
                        color:#DA9501;
                        text-decoration: none;
                    }
                }
            }
            button {
                &:focus {
                    color:initial;
                    text-decoration: none;
                }
            }
        }
        .card-body {
            background-color: #ffffff;
            .text {
                .header {
                    justify-content: flex-start;
                    color:#694938;
                }
                span {
                    font-weight: 300;
                    font-weight: 18px;
                    padding: 3px;
                    &:nth-child(even) {
                        color: #252628;
                    }
                    &:nth-child(odd) {
                        color: #8D919A;
                    }
                    svg {
                        width: 20px;
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}

  
.full-page-wrapper {
    width: 100%;
    min-height: 100vh;
    .header {
        color: #694938;
        font-size: 18px;
        font-weight: 300px;
    }
    .form-card {
        background-color: white;
        padding: 2em;
        box-shadow: 1px 1px 8px 1px #eeeeec;
    }
    img {
        max-width: 100%;
    }
}

.user-sidebar-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #ededed;
    margin-bottom: 20px;
    .details { 
      text-align: center;
      h3 {
        word-wrap: break-word;
      }
      .owner-name {
        font-size: 16px;
        font-weight: 600;
        color: #444;
        word-wrap: break-word;
      }
    }
    .avatar {
      img {
        border-radius: 50%;
        width: 70px;
      }
    }
    .user-contact-info {
      display: flex;
    }
  }