.full-width {
    width: 100%;
}

.sub-title {
  font-weight: 300;
  font-size: 22px;
}

.telegram-link {
  display: none;
}

.white-space-normal {
  white-space: normal;
}