.trevda {
    min-height: 350px;
    padding: 11px;
    border-radius: 10px;
    background-color: #ffffff;
    a {
        color: #252628;
        .trevda-card {
            .trevda-image {
                position: relative;
                img {
                    width: 100%;
                    height: 100%;
                    filter: grayscale(100%);
                }
                .trevda-image-watch {
                    position: absolute;
                    bottom: 5px;
                    right: 5px;
                    padding: 5px 10px 5px 10px;
                    background: rgba(94, 108, 117, 0.8);
                    color: white;
                }
                .image-overflow {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    & > div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 95px;
                        height: 30px;
                        background: #ffffff;
                        opacity: 0.8;
                        border-radius: 5px;
                        margin-bottom: 2px;
                        text-transform: uppercase;
                        color: #8f634c;
                        font-weight: 400;
                        font-size: 14px;
                    }
                    .top {
                        color: #da9501;
                        border: 1px solid #da9501;
                    }
                }
                .wish {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
            }
        }

        .info-box {
            .header {
                .title {
                    display: flex;
                    justify-content: space-between;
                    font-weight: 400;
                    font-size: 18px;
                    .price {
                        font-weight: 700;
                    }
                }
                .description {
                    display: block;
                    position: relative;
                    padding-left: 24px;
                    &:before {
                        content: url("../v2-img/icons/location.svg");
                        display: block;
                        position: absolute;
                        top: 55%;
                        left: 0px;
                        transform: translate(0%, -45%);
                    }
                }
                .priceBlock {
                    & > span {
                        display: block;
                        position: relative;
                        padding-left: 24px;
                    }
                    .area {
                        display: block;
                        position: relative;
                        &:before {
                            content: url("../v2-img/icons/buffer.svg");
                            display: block;
                            position: absolute;
                            top: 3px;
                            left: 0px;
                        }
                    }
                    .area-price {
                        display: block;
                        position: relative;
                        &:before {
                            content: url("../v2-img/icons/area_price.svg");
                            display: block;
                            position: absolute;
                            top: 3px;
                            left: 0px;
                        }
                    }
                    .price {
                        font-weight: 700;
                    }
                }
            }
        }
        .green {
            color: #7bb74d;
        }
        .blue {
            color: #018cda;
        }
        .red {
            color: #da0142;
        }
    }

    &:hover {
        box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.1);
        a {
            color: inherit;
            .trevda-image {
                img {
                    filter: none;
                }
            }
        }
    }
}

.trevda-list {
    margin: 2rem 3rem;
}

.trevda-profile {
    .trevda-title-box {
        background: #eeeeec;
        color: #252628;
        font-weight: 400;
        font-size: 18px;
        vertical-align: middle;
        .text-row {
            div {
                padding: 10px 15px;
                text-align: center;
            }
        }
        &:first-child {
            border-top: 2px solid #eeeeec;
        }
    }
    .item {
        border: 2px solid #eeeeec;
        border-top: 0;
        padding: 25px 0;
        align-items: center;
        margin: 0;
        position: relative;
        .action-box {
            padding: 0;
            p:hover {
                color: #d49000;
            }
        }
        .view-box {
            padding-left: 10px;
        }
        .view {
            span {
                padding-right: 5px;
                color: #000000;
                font-weight: 300;
                font-size: 14px;
            }
        }
        .grid {
            display: grid;
            margin-left: 10px;
            .text {
                grid-row-start: 1;
                grid-row-end: 1;
                grid-column-start: 1;
                grid-column-end: 2;
                a {
                    color: #252628;
                    font-weight: 300;
                    font-size: 18px;
                }
            }
            .date {
                grid-row-start: 2;
                grid-row-end: 2;
                grid-column-start: 1;
                grid-column-end: 2;
                p {
                    margin: 0;
                    font-size: 14px;
                    color: #8d919a;
                }
            }
            .hidden-checkbox {
                display: none;
            }
        }
        .media-text-row {
            display: none;
        }
        .danger {
            color: #dc3545;
            &:before {
                content: url("../v2-img/icons/danger-not-check.svg");
            }
        }
        .normal {
            color: #7bb74d;
            &:before {
                content: url("../v2-img/icons/success-check.svg");
            }
        }
        .warning {
            color: #da9501;
            &:before {
                content: url("../v2-img/icons/hourglass.svg");
            }
        }
    }
}

.preferred {
    background-color: #eeeeec;
    padding: 40px;
    box-shadow: 1px 1px 5px 1px #d8d8d8;
}