::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
    border-radius: 4px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #818387; 
    border-radius: 4px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
      background: darken($color: #818387, $amount: 10); 
  }