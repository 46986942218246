.header-form {
    .container {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        padding: 12px 75px 12px 0;
        background: #ffffff;
        box-shadow: 2px 2px 11px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        z-index: 1;
    }
    .mobile-search {
        display: none;
        position: absolute;
        top: 54vh;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 1;
    }
}

span.select2.select2-container {
    min-width: 100% !important;
    max-width: 181px;
}

.select2-container--default {
    &.select2-container--default {

        .select2-selection {
            &.select2-selection--multiple {
                border: 2px solid #EEEEEC;
                border-radius: 5px;
                min-height: 45px;
                background-color: transparent;
            }
        }
    }
    &.select2-container--open {
        .select2-results {
            > .select2-results__options {
                max-height: 200px;
                overflow-y: auto;
            }
        }
        .select2-results__option--highlighted[aria-selected] {
            background-color: #d49000;
            color: white;
        }
    }
}


.select2-container--material {
    .select2-selection--single {
        background-color: #ffffff;
        border: none;
        border-right: 1px solid #eeeeec;
        border-radius: 0;
        box-shadow: none;
        box-sizing: content-box;
        height: 31px;
        margin: 0;
        outline: none;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        text-align: center;
        .select2-selection__rendered {
            color: #444;
            line-height: 28px;
            padding-left: 10px;
            font-weight: 400;
        }
        .select2-selection__clear {
            cursor: pointer;
            float: right;
            font-weight: bold;
        }
        .select2-selection__placeholder {
            color: #000;
            font-weight: 400;
        }
        .select2-selection__arrow {
            height: 26px;
            margin: 0.6rem 0 0.4rem 0;
            position: absolute;
            top: -8px;
            left: 90%;
            width: 20px;
            b {
                border-color: #000 transparent transparent transparent;
                border-style: solid;
                border-width: 4px 3px 0 3px;
                height: 0;
                left: 50%;
                margin-left: -4px;
                margin-top: -2px;
                position: absolute;
                top: 50%;
                width: 0;
            }
        }
    }
    .select2-search--dropdown {
        .select2-search__field {
            border: 1px solid #b1b1b1;
            &:focus {
                &:not([readonly]) {
                    box-shadow: 0 1px 0 0 #ced4da;
                    border-bottom: 1px solid #ced4da;
                }
            }
        }
    }
    .select2-search--inline {
        .select2-search__field {
            background: transparent;
            border: none !important;
            outline: 0;
            box-shadow: none !important;
            -webkit-appearance: textfield;
        }
    }
    .select2-results {
        > .select2-results__options {
            max-height: 200px;
            overflow-y: auto;
        }
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: #d49000;
        color: white;
    }
}
.select2-container--material[dir="rtl"] {
    .select2-selection--single {
        .select2-selection__clear {
            float: left;
        }
        .select2-selection__arrow {
            left: 1px;
            right: auto;
        }
    }
}
.select2-container--material.select2-container--material--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent #000 transparent;
                border-width: 0 4px 5px 4px;
            }
        }
    }
}
.select2-container--open {
    .select2-dropdown {
        left: 0;
        border-color: #d4d5d8;
    }
    .select2-dropdown--above {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .select2-dropdown--below {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border: 1px solid #efefed;
    }
}
.select2-search--dropdown {
    display: block;
    padding: 4px;
    .select2-search__field {
        padding: 4px;
        width: 100%;
        box-sizing: border-box;
        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }
    }
}
.select2-search--dropdown.select2-search--hide {
    display: none;
}

.select-hidden {
    display: none;
    visibility: hidden;
    padding-right: 10px;
}

.select {
    box-sizing: content-box;
    width: 100%;
    cursor: pointer;
    display: inline-block;
    position: relative;
    font-size: 14px;
    color: #000;
    border-right: 1px solid #ddd;
    height: 27px;
    text-align: center;
}

.select-styled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    padding: 2px 20px 4px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;

    &:after {
        content: "";
        width: 0;
        height: 0;
        border-color: #000 transparent transparent transparent;
        border-style: solid;
        border-width: 4px 3px 0 3px;
        position: absolute;
        top: 13px;
        right: 6px;
    }
}

.select-options {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #fff;
    height: auto;
    font-size: 14px;
    border: 1px solid lightgray;

    li {
        margin: 0;
        padding: 12px 10px;
        border-top: 1px solid darken(#ffffff, 10);

        &:hover {
            background-color: #d49000;
            color: #fefefe;
        }

        &[rel="hide"] {
            display: none;
        }
    }
}

.aside-filter-box {
    .select2-container--material {
        .select2-selection--single {
            border: none;
            border-bottom: 1px solid #eeeeec;
        }
    }
    .trevda-type {
        display: flex;
        flex-wrap: wrap;
        margin: 1rem 0;
        padding:0;
        .option {
            width: 49%;
            padding: 4px 0;
            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
                position: absolute;
                left: -9999px;
            }
        
            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label {
                position: relative;
                // padding-left: 28px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                color: #DA9501;
                background-color: transparent;
                border: 1px solid #DA9501;
                border-radius: 5px;
                padding: 6px 30px;
                width: 100%;
                text-align: center;
            }
        
            [type="radio"]:checked + label {
                background-color: #DA9501;
                color: #ffffff;
            }

            [type="radio"]:not(:checked) + label {
                &:hover {
                    background-color: #DA9501;
                    color: #ffffff;
                }
            }
            &:last-child {
                width: 100%;
            }
            &:first-child {
                margin-right: 2%;
            }
        }
    }
    .filter-price,
    .filter-area {
        span {
            color: #252628;
            font-size: 16px;
        }
    }
}


.area-sort-box,
.price-sort-box {
    position: relative;

    i {
        position: absolute;
        top: 4px;
        left: -12px;
    }

    a.area-sort-title,
    a.price-sort-title {
        color: #111;
    }

    .active {
        color: #d39000 !important;
    }

    .fa {
        cursor: pointer;
    }

    .non-active {
        display: none;
    }
}


.settings-select {
    .select {
        border: 0;
        .select-styled {
            background-color: #EEEEEC;
            border-radius: 60px;
        }
        .select-options {
            border: 0;
            box-shadow: 1px 1px 10px 1px #eeeeec;
            border-radius: 10px;
            li {
                border-radius: 10px;
            }
        }
    }
}