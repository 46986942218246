@import 'base/fonts.scss';
@import 'base/reset.scss';

@import 'abstracts/structure.scss';

@import 'components/advert.scss';
@import 'components/breadcrumbs.scss';
@import 'components/buttons.scss';
@import 'components/filter.scss';
@import 'components/forms.scss';
@import 'components/input.scss';
@import 'components/links.scss';
@import 'components/lists.scss';
@import 'components/map.scss';
@import 'components/modal.scss';
@import 'components/news.scss';
@import 'components/owl.scss';
@import 'components/pagination.scss';
@import 'components/progress.scss';
@import 'components/scroll.scss';
@import 'components/tables.scss';
@import 'components/widgets.scss';

@import 'layout/footer.scss';
@import 'layout/header.scss';
@import 'layout/content.scss';

@import 'pages/details.scss';
@import 'pages/add-edit-advert.scss';
@import 'pages/scoring.scss';

@import 'base/media.scss';
