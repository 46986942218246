a {
    color: #8D919A;
    text-decoration: none;
    svg {
        vertical-align: middle;
    }
    &:hover {
        color: #DA9501;
        text-decoration: none;
        svg {
            path {
                fill: #DA9501;
            }
        }
    }
    &.languageLink {
        font-weight: bold;
    }
}

.brown-link {
    color: #DA9501 !important;
    &:hover {
        text-decoration: underline;
    }
}

.disabled {
    svg {
        path {
            fill:#8D919A;
        };
    }
    pointer-events: none !important;
    color: #8D919A !important;
}