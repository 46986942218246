.form-control {
    border: 2px solid #EEEEEC;
    border-radius: 5px;
    min-height: 45px;
    background-color: transparent;
    &:focus {
        border: 2px solid #DA9501;
    }
}

textarea {
    &.form-control {
        height: 130px;
    }
}

#landlot-create-form {
    .status {
        li {
            label {
                display: flex;
                align-items: center;
                margin:9px;
                input {
                    min-height: 10px;
                    width: 35px;
                    filter: hue-rotate(190deg);
                }
            }
        }
    }
}

.delete-form {
    text-align: center;
    border: 1px solid #ededed;
    padding: 20px;
    border-color: transparent;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.11);
  }
  

