.article-title {
    color: #694938;
    font-weight: 300;
    font-size: 18px;
    text-transform: uppercase;
}

.trevda-location {
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    color: #252628;
    padding-bottom: 0.6em;
    border-bottom:1px solid #C4C4C4; ;
}

.share-block {
    text-align: end;
    color: #DA9501;
    font-size: 14px;
    a {
        color: #DA9501;
    }
}

.key-value-row {
    font-size: 14px;
    color: #252628;
    .key {
        font-weight: 400;
    }
    .value {
        font-weight: 300;
    }
    .total-price {
        color: #DA9501;
        font-size: 28px;
        font-weight: 700;
    }
    .price-for {
        color: #694938;
        font-size: 22px;
        font-weight: 700;
    }
}