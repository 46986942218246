.gradient {
    background: linear-gradient(transparent 70%, #ffffff);
    position: absolute;
    width: 100%;
    height: 70vh;
    z-index: 1;
}

.map-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 400px;
    #map {
        position: absolute;
        top:0;
        right: 0;
        left:0;
        bottom: 0;
    }
}

#map-main {
    &.mapboxgl-map {
        .mapboxgl-control-container {
            .mapboxgl-ctrl-top-left {
                top: 55px;
            }
        }
    }
}

.mapboxgl-map {
    .mapboxgl-control-container {
        .mapboxgl-ctrl-top-left {
            top: 0px;
            right: 0;
            .mapboxgl-ctrl {
                padding: 5px;
                font-family: 'Open Sans', sans-serif;
                font-weight: 300;
                box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                background-color: rgba(255,255,255,0.9);
                .mapbox-ctrl-layers-layer {
                    display: flex;
                    align-items: center;
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }
                    label {
                        margin: 0 0 0 15px;
                        font-size: 14px;
                    }
                }
            }
        }
        .mapboxgl-ctrl-top-right {
            top: 50%;
            right: 0;
            transform: translate(0%, -50%);
            .mapboxgl-ctrl { 
                .mapboxgl-ctrl-zoom-in {
                    .mapboxgl-ctrl-icon {
                        background-image: url('../v2-img/icons/plus.svg');
                    }
                }
                .mapboxgl-ctrl-zoom-out {
                    .mapboxgl-ctrl-icon {
                        background-image: url('../v2-img/icons/minus.svg');
                    }
                }
                .mapboxgl-ctrl-compass {
                    .mapboxgl-ctrl-icon {
                        background-image: url('../v2-img/icons/compass.svg');
                    }
                }
                .mapboxgl-ctrl-geolocate {
                    .mapboxgl-ctrl-icon {
                        background-image: url('../v2-img/icons/geolocation.svg');
                    }
                }
                .mapboxgl-ctrl-fullscreen {
                    .mapboxgl-ctrl-icon {
                        background-image: url('../v2-img/icons/full_maps.svg');
                    }
                }
            }
        }
        .mapboxgl-ctrl-bottom-left {
            display: none;
        }
        .mapboxgl-ctrl-bottom-right {
            display: none;
        }
    }
}

.landboard-map {
    width: 400px;
    height: 300px;
    float: right;
}

.mapboxgl-popup {
    .mapboxgl-popup-close-button {
        color: #da9501;
        font-size: 14px;
    }
}

.mapboxgl-popup {
    z-index: 1;
}