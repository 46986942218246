header {
    .mobile-menu {
        visibility: hidden;
        opacity: 0;
        position: fixed;
        top: 55px;
        left: 0px;
        width: 25%;
        background-color: #EEEEEC;
        height: calc(100vh - 55px);
        z-index: 10;
        transition: all 0.6s ease-in-out;
        font-size: 21px;
        font-weight: 300;
        ul {
            padding-left: 70px;
            li {
                margin-bottom: 5px;
            }
        }
        &.show {
            visibility: visible;
            opacity: 1;
            &:after {
                content:"";
                background-color: rgba(204,201,201,0.7);
                width: 75%;
                height: 100%;
                position: fixed;
                top: 0;
                right: 0;
            }
        }
        .contacts {
            height: 150px;
            position: absolute;
            bottom: 0;
            left: 50%;
            text-align: center;
            transform: translate(-50%,0);
            ul {
                li {
                    margin-bottom: 10px;
                }
                a {
                    font-size: 16px;
                    color: #8F634C;
                }
                img {
                    width: 53px;
                }
            }

        }
    }
    .user-menu {
        visibility: hidden;
        opacity: 0;
        padding: 0px 26px;
        position: fixed;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 8px 8px;
        top: 55px;
        right: 0px;
        z-index: 3;
        transition: all 600ms;
        &.show {
            visibility: visible;
            right: 20px;
            opacity: 1;
        }
        &>div {
            border-bottom: 1px solid #EEEEEC;
            border-radius: 4px;
            padding: 15px 0;
            color:#694938;
            &:last-child {
                border-bottom: none;
            }
            .user-name {
                font-size: 18px;
                font-weight: 400;
            }
            ul {
                margin: 0;
                padding: 0;
            }
            a{
                display: flex;
                align-items: center;
                color: #8D919A;
                svg {
                    margin-right: 10px;
                }
                &:hover {
                    color: #8F634C;
                    svg {
                        path {
                            fill: #DA9501;
                        }
    
                    }
                }
            }
        }
    }
    .navbar {
        display: flex;
        justify-content: flex-end;
        background-color:rgba(255,255,255, 0.9);
        overflow: hidden;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
        height: 55px;
        margin: 0 auto;
        .burger-menu {
            display: none;
            position: relative;
            .line {
                &:first-child {
                    width: 26px;
                }
                &:last-child {
                    width: 26px;
                }
                width: 22px;
                height: 2px;
                background-color: #8D919A;
                margin: 5px;
                border-radius: 8px;
                transition: 0.4s;
            }
            &:hover {
                cursor: pointer;
            }
            &.change {
                .line {
                    opacity: 0;
                    &:first-child {
                        opacity: 1;
                        transform: rotate(-45deg) translate(-5px, 5px);
                        background-color: #f8aa02;
                    }
                    &:last-child {
                        opacity: 1;
                        transform: rotate(45deg) translate(-5px, -5px);
                        background-color: #f8aa02;
                    }
                }
            }
        }
        .logo {
            position: absolute;
            left: 0%;
            transform: translate(0%, 0%);
            padding: 0 16px;
            img {
                width: 250px;
            } 
        }
        .navbar-menu-wrapper {
            .navbar-nav {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                align-items: center;
                padding-left: 0;
                margin-bottom: 0;
                list-style: none;
                .nav-item {
                    margin: 0px 10px;
                    &.notification {
                        position: relative;
                        order:0;
                        .number {
                            position: absolute;
                            left: 21px;
                            background-color: #6CCA21;
                            border-radius: 10px;
                            padding: 0px 4px;
                            font-size: 12px;
                        }
                    }
                    &.wishList {
                        order: 1;
                        align-self: flex-end;
                        a {
                            content: url('../v2-img/icons/bookmark.svg');
                            &:hover {
                                content: url('../v2-img/icons/bookmark_active.svg');  
                            }
                        }
                    }
                    &.subscribe {
                        order: 1;
                    }
                    &.languages {
                        order: 2;
                        line-height: 1;
                        text-transform: uppercase;
                    }
                    &.login {
                        order: 3;
                        .loginButton {
                            display: flex;
                            align-items: center;
                            &:before {
                                content: url('../v2-img/icons/arrow.svg');
                                margin-right: 5px;
                            }
                            img {
                                margin-right: 5px;
                            }
                        }
                    }
                    &.profileButton {
                        order: 3;
                        a {
                            display: flex;
                            align-items: center;
                            &:after {
                                content: url('../v2-img/icons/angle_down.svg');
                            }
                        }
                        &.show {
                            a {
                                img {
                                    content: url('../v2-img/icons/login_active.svg');
                                }
                                svg {
                                    path {
                                        fill: #DA9501;
                                    }
                                }

                                &:after {
                                    content: url('../v2-img/icons/angle_up.svg');
                                }
                            } 
                        }
                    }
                }
            }
        }
    }
}
